<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {},
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-family: HYQiHei-EZS, HYQiHei;
}
ul li {
  list-style: none;
}
#app {
  width: 100%;
  height: 100%;
}
//登录页面input样式更改
.login-container {
  .el-input__inner {
    height: 60px !important;
    line-height: 60px !important;
    border-radius: 10px;
  }
}
.TUIChat-footer {
  //聊天页面底部输入消息去掉border
  .el-textarea__inner {
    padding: 5px 3px;
    border: none !important;
  }
}
//强提醒多选组
.remind_box {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  .el-checkbox {
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
}
.el-textarea__inner{
  resize: none !important;
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.use(Router)
const routerRePush = Router.prototype.push
Router.prototype.push = function (location) {
  return routerRePush.call(this, location).catch(error => error)
}
export default new Router({
  mode: 'history',
  routes: [
    // { path: '/', redirect: '/Chat' },
    {
      path: '/',
      name: 'Home',
      component: () => import('@/page/home'),
    },
    { path: '*', redirect: '/' },
    {
      path: '/Login',
      name: 'Login',
      component: () => import('@/page/login'),
    },
    {
      path: '/Chat',
      name: 'Chat',
      component: () => import('@/page/chat'),
    },
  ]
})

